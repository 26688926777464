import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  Input
} from '@angular/core';
import { EventBusService } from '../../../services/EventBusService';
import { TrackingService } from 'app/services/tracking.service';

declare var jQuery: any;
declare var window: any;

@Component({
  selector: 'footer-c',
  templateUrl: './footer.html',
  styleUrls: ['./footer.scss']
})
export class Footer implements OnInit, AfterViewInit {
  version: string;
  showFeedbackForm: boolean;

  @Input() delay: number;
  isShowFeedback: boolean;
  isScrolling: any;
  year: string;

  constructor(
    private tracking: TrackingService,
    private eventBus: EventBusService
  ) {
    this.version = '2.2.5';
    this.year = new Date().getFullYear().toString();
    this.eventBus.showFeedbackButtonEvent.subscribe(value => {
      if (value) {
        this.isShowFeedback = true;
        this.setupFooter();
      } else {
        this.isShowFeedback = false;
        jQuery('.feedback').css('margin-bottom', '0px');
      }
    });
  }

  setupFooter() {
    // Run the callback
    let elementTop = jQuery('.footer-cont .container-fluid').offset().top;
    let viewportTop = jQuery(window).scrollTop();
    let viewportBottom = viewportTop + jQuery(window).height();

    let visibleHeight = viewportBottom - elementTop;
    if (visibleHeight > 0) {
      jQuery('.feedback').animate(
        { 'margin-bottom': visibleHeight + 'px' },
        100
      );
      //jQuery('.feedback-form').animate({'margin-bottom': (visibleHeight + 45) + 'px'},100);
    } else {
      jQuery('.feedback').animate({ 'margin-bottom': '0px' }, 100);
      //jQuery('.feedback-form').animate({'margin-bottom': '45px'},100);
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (this.isShowFeedback) {
      // Clear our timeout throughout the scroll
      window.clearTimeout(this.isScrolling);

      // Set a timeout to run after scrolling ends
      this.isScrolling = setTimeout(() => {
        this.setupFooter();
      }, 66);
    }
  }

  ngOnInit() {}

  ngAfterViewInit(): void {}

  sendFeedback() {
    let message = jQuery('.feedback-form textarea').val();

    if (message && message.length > 0) {
      let props = { feedbackMsg: message };
      this.tracking.eventTrack('mrt_feedback', props, true);
      this.showFeedbackForm = false;
    }
  }

  showFeedback() {
    jQuery('.feedback-form textarea').val('');
    setTimeout(fn => {
      jQuery('.feedback-form textarea').focus();
    }, 100);
    this.showFeedbackForm = !this.showFeedbackForm;
  }
}
