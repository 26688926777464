import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NewNavigationComponent } from './new-navigation.component';
import { AuthGuard } from '../../services/authguard-service';
import { DomainDetailsLegacyComponent } from './domain-details-legacy/domain-details-legacy.component';
import { AppDetailsLegacyComponent } from './app-details-legacy/app-details-legacy.component';
import { LandingPageRoutingComponent } from './landing-page-routing/landing-page-routing.component';
import { RankingsPageRoutingComponent } from './rankings-page-routing/rankings-page-routing.component';
import { DiscoveryPageRoutingComponent } from './discovery-page-routing/discovery-page-routing.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { EnterpriseGuard } from 'app/guards/enterprise-guard';
import { MetaGuard } from 'app/guards/meta.guard';

const appRoutes: Routes = [
  {
    path: '',
    component: NewNavigationComponent,
    canActivate: [AuthGuard],
    canLoad: [MetaGuard],
    children: [
      { path: '', component: LandingPageRoutingComponent },
      { path: 'rankings', component: RankingsPageRoutingComponent },
      {
        path: 'discovery',
        component: DiscoveryPageRoutingComponent,
        pathMatch: 'full'
      },
      {
        path: 'watchlist',
        component: DiscoveryPageRoutingComponent,
        pathMatch: 'full'
      },
      {
        path: 'freemium-watchlist',
        loadChildren: () =>
          import('./freemium-watchlist/freemium-watchlist.module').then(
            m => m.FreemiumWatchlistModule
          )
      },
      {
        path: 'rankings',
        loadChildren: () =>
          import('./rankings/rankings.module').then(m => m.RankingsModule)
      },
      {
        path: 'insight',
        loadChildren: () =>
          import('./insights/insights.module').then(m => m.InsightsModule)
      },
      {
        path: 'discovery',
        loadChildren: () =>
          import('./discovery/discovery.module').then(m => m.DiscoveryModule)
      },
      {
        path: 'imported-csv',
        loadChildren: () =>
          import('./imported-csv/imported-csv.module').then(
            m => m.ImportedCsvModule
          )
      },
      {
        path: 'user-profile',
        canLoad: [EnterpriseGuard],
        loadChildren: () =>
          import('./userprofile/userprofile.module').then(
            m => m.UserProfileModule
          )
      },
      {
        path: 'account-settings',
        canLoad: [EnterpriseGuard],
        loadChildren: () =>
          import('./userprofile/userprofile.module').then(
            m => m.UserProfileModule
          )
      },
      {
        path: 'domain-details/:domain/:region/:device',
        component: DomainDetailsLegacyComponent
      },
      {
        path: 'app-details/:appid/:region/:os/:device',
        component: AppDetailsLegacyComponent
      },
      {
        path: 'search',
        loadChildren: () =>
          import(
            './keyword-contextual-search/keyword-contextual-search-routing.module'
          ).then(m => m.KeywordContextualSearchRoutingModule),
        data: { disableSearchOverlay: true }
      },
      { path: '**', component: PageNotFoundComponent }
    ]
  }
];

export const appRoutingProviders: any[] = [];

export const routing: ModuleWithProviders<any> =
  RouterModule.forChild(appRoutes);
