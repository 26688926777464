import { Angulartics2Module } from 'angulartics2';
import { ApigeeService } from './services/apigee.service';
import { AppComponent } from './app.component';
import { AppInjector } from './services/app-injector.service';
import { AppService } from './services/app-service';
import {
  BrowserModule,
  Title,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG
} from '@angular/platform-browser';
import { ChangelogService } from './services/changelog-service';
import { CountryCodeService } from './services/country-code-service';
import { CtvService } from './services/ctv-service';
import { DomainService } from './services/domain-service';
import { environment } from '../environments/environment';
import { EventBusService } from './services/EventBusService';
import { FormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { InsightsRouterLinkPipe } from './pipes/insightsrouterlink.pipe';
import { interceptorProviders } from './interceptors/interceptorProviders';
import { NewNavigationModule } from './components/new-navigation/new-navigation.module';
import { RankingsAnonFormService } from './services/rankings-anon-form.service';
import { routing, appRoutingProviders } from './app.routing';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TooltipService } from './services/tooltip-service';
import { TrackingService } from './services/tracking.service';
import { User } from './models/User';
import { WatchlistService } from './services/watchlist-service';
import { BaseApiService } from './services/base-api-service';
import * as Hammer from 'hammerjs';
import { QuotaService } from './services/quota-service';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL }
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    routing,
    NewNavigationModule,
    Angulartics2Module.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      // registrationStrategy: 'registerWhenStable:30000'
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    appRoutingProviders,
    interceptorProviders,

    ApigeeService,
    AppService,
    BaseApiService,
    ChangelogService,
    CountryCodeService,
    CtvService,
    DomainService,
    EventBusService,
    InsightsRouterLinkPipe,
    QuotaService,
    RankingsAnonFormService,
    Title,
    TooltipService,
    TrackingService,
    User,
    WatchlistService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.injector = injector;
  }
}
