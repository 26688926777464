/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Injectable } from '@angular/core';
import { User } from '../models/User';
import { BaseApiService } from './base-api-service';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';

declare let jQuery: any;

@Injectable()
export class DomainService extends BaseApiService {
  public region = 'US';
  public device = 'Desktop';
  public month: string;

  domainIconCoordinates: any;
  domainDetailsCache: any = {};

  availableMonthsCache: any = {};
  domainCachedObservables: any = {};
  facetsDataCache: any = {};
  discoveryDataCache: any = {};
  isFollowing = false;

  constructor(
    private http: HttpClient,
    private userService: User,
    private auth: AuthService
  ) {
    super();
  }

  storeDomainDetailsCache(domain: string, cache: any) {
    this.storeCache(cache, this.domainDetailsCache, domain);
  }

  storeDomainIconCoordinates(coordinates: any) {
    this.domainIconCoordinates = coordinates;
  }

  setRegion(region: string): void {
    this.region = region;
  }

  setDevice(device: string): void {
    this.device = device;
  }

  setMonth(month: string): void {
    this.month = month;
  }

  setFollowing(isFollowing: boolean) {
    this.isFollowing = isFollowing;
  }

  private makeSectionRequest(
    domain: string,
    api: string,
    deviceType: string,
    country: string,
    month?: string
  ) {
    let query = '';
    if (deviceType && deviceType !== '') {
      query += ` AND deviceType = '${deviceType}' `;
    }

    if (month && month !== '') {
      query += ` AND month = '${month}' `;
    }

    let urlString = `${this.getReportBaseUrl()}/getWidget?q=WHERE adDomain='${domain}' AND countryCode='${country}' ${query} &`;
    urlString += `reportId=mrt&widgetId=domainSummary&sectionId=${api}`;
    return this.http.get(urlString);
  }

  getAllData(domain: string, regionParam?: string, deviceParam?: string) {
    const region = regionParam ? regionParam : this.region;
    const device = deviceParam ? deviceParam : this.device;
    const month = this.month;
    const isFreemium = this.userService.isFreemium();

    let urlString = '';
    if (isFreemium) {
      const queryParams = {
        region: region,
        device: device,
        month: month,
        isFreemium: isFreemium,
        isFollower: this.isFollowing
      };
      const params = jQuery.param(queryParams);

      urlString = `https://${this.getApigeeHost()}/api/v1/insights/domains/${domain}?${params}`;
    } else {
      let query = '';
      if (device !== '') {
        query += ` AND deviceType = '${device}' `;
      }
      if (month && month !== '' && month !== 'last30Days') {
        query += ` AND month = '${month}' `;
      }

      urlString = `${this.getReportBaseUrl()}/getWidget?q=WHERE adDomain='${domain}' AND countryCode='${region}' ${query} &`;
      urlString += `reportId=mrt&widgetId=domainSummary`;
    }

    return this.getCachedObservable(urlString);
  }

  getAvailableFilters(
    domain: string,
    regionParam?: string,
    deviceParam?: string
  ) {
    const region = regionParam ? regionParam : this.region;
    const device = deviceParam ? deviceParam : this.device;

    let query = `WHERE adDomain='${domain}' AND countryCode='${region}'`;
    if (device !== '') {
      query += ` AND deviceType = '${device}'`;
    }

    const urlString =
      `${this.getReportBaseUrl('2018')}/getWidget?reportId=mrt&` +
      `widgetId=availableFilters&q=${query}`;

    return this.getCachedObservable(urlString);
  }

  getCachedObservable(urlString: string) {
    const cacheKey = `${this.auth.getAuthState()}:${urlString}`;
    let cachedObservable = this.domainCachedObservables[cacheKey];

    if (!cachedObservable) {
      cachedObservable = this.http.get(urlString).pipe(shareReplay());
      this.domainCachedObservables[cacheKey] = cachedObservable;
    }

    return cachedObservable.toPromise();
  }

  getOverallData(
    domain: string,
    region: string,
    device: string,
    month: string
  ) {
    return new Promise(resolve => {
      const api = 'overview';
      this.makeSectionRequest(domain, api, device, region, month).subscribe(
        res => {
          resolve(res);
        }
      );
    });
  }

  getRiskData(domain: string) {
    const region = this.region;
    const device = this.device;
    return new Promise((resolve, reject) => {
      const api = 'riskOverview';
      this.makeSectionRequest(domain, api, device, region).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getBrandSafetyData(domain: string) {
    return new Promise((resolve, reject) => {
      const api = 'brandSafety';
      this.makeSectionRequest(domain, api, this.device, this.region).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getSocialMediaData(domain: string) {
    return new Promise(resolve => {
      const api = 'socialMedia';
      this.makeSectionRequest(domain, api, this.device, this.region).subscribe(
        res => {
          resolve(res);
        }
      );
    });
  }

  getInventoryData(domain: string) {
    return new Promise(resolve => {
      const api = 'inventoryDistribution';
      this.makeSectionRequest(domain, api, this.device, this.region).subscribe(
        res => {
          resolve(res);
        }
      );
    });
  }

  getSellerData(domain: string) {
    return new Promise(resolve => {
      const api = 'sellersShareDistribution';
      this.makeSectionRequest(domain, api, this.device, this.region).subscribe(
        res => {
          resolve(res);
        }
      );
    });
  }

  getAdData(domain: string) {
    return new Promise(resolve => {
      const api = 'viewabilityDistribution';
      this.makeSectionRequest(domain, api, this.device, this.region).subscribe(
        res => {
          resolve(res);
        }
      );
    });
  }

  getCtrData(domain: string) {
    return new Promise(resolve => {
      const api = 'ctrDistribution';
      this.makeSectionRequest(domain, api, this.device, this.region).subscribe(
        res => {
          resolve(res);
        }
      );
    });
  }

  getTrafficSourceData(domain: string) {
    return new Promise(resolve => {
      const api = 'trafficDistribution';
      this.makeSectionRequest(domain, api, this.device, this.region).subscribe(
        res => {
          resolve(res);
        }
      );
    });
  }

  getDeviceDistributionData(domain: string) {
    return new Promise(resolve => {
      const api = 'deviceDistribution';
      this.makeSectionRequest(domain, api, this.device, this.region).subscribe(
        res => {
          resolve(res);
        }
      );
    });
  }

  getTopSubDomains(domain: string) {
    return new Promise(resolve => {
      const api = 'subDomainDistribution';
      this.makeSectionRequest(domain, api, this.device, this.region).subscribe(
        res => {
          resolve(res);
        }
      );
    });
  }

  ivtDistributionData(domain: string) {
    return new Promise(resolve => {
      const api = 'ivtDistribution';
      this.makeSectionRequest(domain, api, this.device, this.region).subscribe(
        res => {
          resolve(res);
        }
      );
    });
  }

  programmaticInventoryData(domain: string) {
    return new Promise(resolve => {
      const api = 'programmaticInventory';
      this.makeSectionRequest(domain, api, this.device, this.region).subscribe(
        res => {
          resolve(res);
        }
      );
    });
  }

  getDomains(name: string, l?: number) {
    let limit = 50;
    if (l && l > 0) {
      limit = l;
    }
    const urlString = `${this.getRatingsBaseUrl()}/getDomainMetadata?type=domainInfo&start=0&limit=${limit}&title=${name}`;
    return this.http.get(urlString);
  }

  getFacets(filterString: string) {
    return new Promise((resolve, reject) => {
      let urlString =
        `${this.getRatingsBaseUrl()}/getDomainFacets?facet=risk&facet=brandSafetyRisk&facet=isVideoAvailable&` +
        `facet=isAdsDotTxtExist&facet=primaryDevice&facet=dayOfWeek&facet=adultContentRating&facet=hasTermsOfServiceLink&` +
        `facet=alcoholContentRating&facet=drugContentRating&facet=hateContentRating&facet=trafficSource&facet=mfaRating&` +
        `facet=facebookRating&facet=linkedinRating&facet=twitterRating&facet=isSubDomain&facet=hasPrivacyLink&facet=ownerDomain&` +
        `facet=hasCookieBanner`;
      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }

      if (this.facetsDataCache[urlString]) {
        resolve(JSON.parse(JSON.stringify(this.facetsDataCache[urlString])));
      } else {
        this.http.get(urlString).subscribe(
          res => {
            this.storeCache(res, this.facetsDataCache, urlString);
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
      }
    });
  }

  getFacetsBySearch(
    selectedDevice: string,
    selectedRegion: string,
    isSubDomain: string,
    param: Array<string>,
    paramSearch: string,
    paramSearchValue: string,
    filterString?: string
  ) {
    return new Promise((resolve, reject) => {
      let urlString = `${this.getRatingsBaseUrl()}/getDomainFacets?nocache=${Math.random()}`;

      if (selectedRegion) {
        urlString = `${this.getRatingsBaseUrl()}/getDomainFacets?region=${selectedRegion}`;
      }

      if (param && param.length > 0) {
        for (const p of param) {
          urlString += `&facet=${p}`;
        }
      }

      if (
        paramSearch &&
        paramSearchValue &&
        paramSearch.length > 0 &&
        paramSearchValue.length > 0
      ) {
        urlString += `&${paramSearch}=${paramSearchValue}`;
      }

      if (selectedDevice) {
        const selectedDeviceString =
          selectedDevice === 'smartphone-tablet'
            ? 'device=smartphone&device=tablet'
            : `device=${selectedDevice}`;
        if (selectedDevice && selectedDevice.length > 0) {
          urlString = urlString.concat(`&${selectedDeviceString}`);
        }
      }

      urlString = urlString.concat(`&isSubDomain=${isSubDomain}`);

      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }

      if (this.facetsDataCache[urlString]) {
        resolve(this.facetsDataCache[urlString]);
      } else {
        this.http.get(urlString).subscribe(
          res => {
            this.storeCache(res, this.facetsDataCache, urlString);
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
      }
    });
  }

  checkImportQuota() {
    return new Promise((resolve, reject) => {
      const urlString = `${this.getRatingsBaseUrl()}/accountBalance?reportId=summary`;
      this.http.get(urlString).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  searchForDomains(start: number, limit: number, filterString: string) {
    return new Promise((resolve, reject) => {
      let urlString: string;

      if (this.userService.isFreemium()) {
        urlString = `${this.getFreemiumBaseUrl()}/discovery/domains/summary?start=${start}&limit=${limit}`;
      } else {
        urlString = `${this.getRatingsBaseUrl()}/searchForDomains?start=${start}&limit=${limit}&month=${
          this.month
        }`;
      }
      if (this.month) {
        urlString = `${urlString}&month=${this.month}`;
      }

      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }
      this.http.get(urlString).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  importCSV(formData: FormData) {
    const headers = new Headers();
    headers.append('Content-type', 'application/octet-stream');

    return new Promise(resolve => {
      return this.http
        .post(`${this.getRatingsBaseUrl()}/importDomains`, formData)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            resolve(err);
          }
        );
    });
  }

  getImportList() {
    return new Promise((resolve, reject) => {
      const urlString = `${this.getRatingsBaseUrl('2018')}/listDomainImport`;
      this.http.get(urlString).subscribe(
        res => {
          this.storeCache(res, this.discoveryDataCache, urlString);
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getReportData() {
    return new Promise((resolve, reject) => {
      const urlString = `${this.getRatingsBaseUrl(
        '2018'
      )}/loadReport?reportId=summary`;
      return this.http.get(urlString).subscribe(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getSearchDefaultValues() {
    return new Promise((resolve, reject) => {
      const urlString = `${this.getRatingsBaseUrl()}/load?reportId=search`;
      this.http.get(urlString).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getSearchCategories(name?: string) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl()}/getMetadata?type=domainCategory`;
      if (name && name.length > 0) {
        urlString = urlString.concat(`&name=${name}`);
      }
      this.http.get(urlString).subscribe(res => {
        resolve(res);
      });
    });
  }

  getSearchSellers(start: number, limit: number, name?: string) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl()}/getMetadata?type=sellerId&start=${start}&limit=${limit}`;
      if (name && name.length > 0) {
        urlString = urlString.concat(`&name=${name}`);
      }
      this.http.get(urlString).subscribe(res => {
        resolve(res);
      });
    });
  }

  getDropdownItems(type: string, name: string, start: number, limit: number) {
    return new Promise((resolve, reject) => {
      let urlString = `${this.getRatingsBaseUrl()}/getMetadata?type=${type}&start=${start}&limit=${limit}`;
      if (name && name.length > 0) {
        urlString = urlString.concat(`&name=${name}`);
      }
      this.http.get(urlString).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getSearchAdSizes(name?: string) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl()}/getMetadata?type=adSize&limit=100`;
      if (name && name.length > 0) {
        urlString = urlString.concat(`&name=${name}`);
      }
      this.http.get(urlString).subscribe(res => {
        resolve(res);
      });
    });
  }

  getSearchCountries(start: number, limit: number, name?: string) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl()}/getMetadata?type=countryCode&start=${start}&limit=${limit}`;
      if (name && name.length > 0) {
        urlString = urlString.concat(`&name=${name}`);
      }
      this.http.get(urlString).subscribe(res => {
        resolve(res);
      });
    });
  }

  getSearchStates(start: number, limit: number, region: string, name?: string) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl()}/getMetadata?type=state&namespace=${region}&start=${start}&limit=${limit}`;
      if (name && name.length > 0) {
        urlString = urlString.concat(`&name=${name}`);
      }
      this.http.get(urlString).subscribe(res => {
        resolve(res);
      });
    });
  }

  getSearchRegistrantEmail(start: number, limit: number, name?: string) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl()}/getMetadata?type=registrantEmail&start=${start}&limit=${limit}`;
      if (name && name.length > 0) {
        urlString = urlString.concat(`&name=${name}`);
      }
      this.http.get(urlString).subscribe(res => {
        resolve(res);
      });
    });
  }

  getSearchDMAcodes(start: number, limit: number, name?: string) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl()}/getMetadata?type=dmaCode&start=${start}&limit=${limit}`;
      if (name && name.length > 0) {
        urlString = urlString.concat(`&name=${name}`);
      }
      this.http.get(urlString).subscribe(res => {
        resolve(res);
      });
    });
  }

  getSearchOS(name?: string) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl()}/getMetadata?type=os&limit=50`;
      if (name && name.length > 0) {
        urlString = urlString.concat(`&name=${name}`);
      }
      this.http.get(urlString).subscribe(res => {
        resolve(res);
      });
    });
  }

  getSearchTrafficSource(name?: string) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl()}/getMetadata?type=trafficSource&limit=50`;
      if (name && name.length > 0) {
        urlString = urlString.concat(`&name=${name}`);
      }
      this.http.get(urlString).subscribe(res => {
        resolve(res);
      });
    });
  }

  getAvailableMonths(reportId = 'search'): Promise<any> {
    if (!this.availableMonthsCache[reportId]) {
      this.availableMonthsCache[reportId] = new Promise((resolve, reject) => {
        const urlString = `${this.getRatingsBaseUrl()}/getWidget?reportId=${reportId}&widgetId=availableMonths`;
        this.http.get(urlString).subscribe(
          res => {
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
      });
    }
    return this.availableMonthsCache[reportId];
  }

  downloadCsv(filterString: string) {
    return new Promise((resolve, reject) => {
      const urlString = `${this.getRatingsBaseUrl()}/getExportUri?reportId=search&${filterString}&isAsync=true`;
      this.http.get(urlString).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  pingExportFile(fileUrl: string) {
    return new Promise(resolve => {
      jQuery.ajax({
        url: fileUrl,
        dataType: 'jsonp',
        timeout: 5000,

        success: function (response) {
          resolve(response);
        },
        error: function (parsedjson) {
          resolve(parsedjson);
        }
      });
    });
  }

  getDomainStats(filterString: string) {
    return new Promise((resolve, reject) => {
      let urlString = `${this.getRatingsBaseUrl(
        '2018'
      )}/stats?nocache=${Math.random()}`;
      if (this.month) {
        urlString += `&month=${this.month}`;
      }
      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }

      if (this.discoveryDataCache[urlString]) {
        resolve(this.discoveryDataCache[urlString]);
      } else {
        this.http.get(urlString).subscribe(
          res => {
            this.storeCache(res, this.discoveryDataCache, urlString);
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
      }
    });
  }
}
