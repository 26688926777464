import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-rankings-page-routing',
  templateUrl: './rankings-page-routing.component.html'
})
export class RankingsPageRoutingComponent implements OnInit {
  constructor(private _route: ActivatedRoute, private _router: Router) {}

  ngOnInit(): void {
    // Rankings
    // let paramId = PAGE_OPTIONS.filter((pageOption) => pageOption.id === 'rankings')[0].params[0].id;
    // let pagePrefOption = localStorage.getItem(`${DEFAULT_PAGE_OPTION_LS_PREFIX}rankings-${paramId}`);
    // if(pagePrefOption) {
    //     let pagePrefOptionObj:PreferenceOption = JSON.parse(pagePrefOption);
    //
    //     this._router.navigate(['/rankings', pagePrefOptionObj.id], {replaceUrl: true});
    // } else {
    void this._router.navigate(['/rankings/app/seller-trust-index'], {
      replaceUrl: true
    });
    // }
  }
}
