/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AuthService } from './auth.service';
import { BaseApiService } from './base-api-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../models/User';
import { WebsiteRating } from '../models/WebsiteRating';
import { shareReplay } from 'rxjs/operators';

declare let jQuery: any;

@Injectable()
export class AppService extends BaseApiService {
  country = 'GLOBAL';
  region = 'GLOBAL';
  os = 'ios';
  device = 'GLOBAL';
  month: string;
  appDetailsCachedObservables: any = {};
  appWebsiteCacheData: any = {};
  availableCountriesCache: any = {};
  appIconCoordinates: any;
  // Param to be excluded
  excludeFacetParam: string;
  availableMonthsCache: any = {};

  // previousUrl when clicked on popup
  previousUrl: string;

  discoveryDataCache: any = {};
  facetsDataCache: any = {};
  reportDataCache: any;

  isFollower: boolean;

  constructor(
    private http: HttpClient,
    private userService: User,
    private auth: AuthService
  ) {
    super();
    this.isFollower = false;
  }

  private useAnonApi() {
    return this.userService.isFreemium() || !this.userService.appEnabled();
  }

  setFollowing(isFollower: boolean) {
    this.isFollower = isFollower;
  }

  storeAppIconCoordinates(coordinates: any) {
    this.appIconCoordinates = coordinates;
  }

  setPreviousUrl(previousUrl: string) {
    this.previousUrl = previousUrl;
  }

  getPreviousUrl() {
    return this.previousUrl;
  }

  setRegion(region: string) {
    this.region = region;
  }

  setCountry(country: string) {
    this.country = country;
  }

  setOs(os: string) {
    this.os = os;
  }

  setDevice(device: string) {
    this.device = device;
  }

  setMonth(month: string) {
    this.month = month;
  }

  getPopularApps() {
    return new Promise(resolve => {
      const popularApps = [
        {
          icon: 'http://is5.mzstatic.com/image/thumb/Purple118/v4/f3/99/43/f3994339-0b72-71ed-0ba2-d0bac7eb4c3f/source/512x512bb.jpg',
          name: 'Pandora Music',
          appId: '284035177',
          region: 'NA',
          os: 'ios',
          device: 'smartphone'
        },
        {
          icon: 'https://lh3.googleusercontent.com/endIvnesDgK8p1Yeotk0v7EGmw3FCwwq1_AfvluJz4lwee13Yo7YnHvucGSL6nMQLWE=w72',
          appId: 'com.accuweather.android',
          region: 'NA',
          os: 'android',
          device: 'smartphone',
          name: 'AccuWeather'
        },
        {
          icon: 'https://lh3.googleusercontent.com/CZL5kc9O-Xeuy5iBdh6Mj8b8oS8eQPD5PED-NwsLUpY28mRoiGqZ1e-KrKY-rW_3WB0=w72',
          appId: 'com.freerange360.mpp.businessinsider',
          region: 'NA',
          os: 'android',
          device: 'smartphone',
          name: 'Business Insider'
        },
        {
          icon: 'http://is4.mzstatic.com/image/thumb/Purple128/v4/2e/48/0c/2e480c90-ace4-71a0-6fa9-4fa6ad98fad6/source/512x512bb.jpg',
          appId: '300704847',
          region: 'NA',
          os: 'ios',
          device: 'smartphone',
          name: 'Speedtest by Ookla'
        }
      ];
      resolve(popularApps);
    });
  }

  getAllData(
    appid: string,
    os?: string,
    deviceOption?: string,
    regionOption?: string,
    countryParam?: string,
    monthParam?: string
  ) {
    const device = os ? os : this.os;
    const country = countryParam ? countryParam : this.country;
    const region = regionOption ? regionOption : this.region;
    const deviceType = deviceOption ? deviceOption : this.device;
    const month = monthParam ? monthParam : this.month;
    const isFreemium = this.useAnonApi();
    const isFollower = this.isFollower;

    let urlString = '';
    if (isFreemium) {
      const queryParams = {
        region,
        os: device,
        country,
        month,
        isFreemium,
        isFollower
      };
      const params = jQuery.param(queryParams);

      urlString = `https://${this.getApigeeHost()}/api/v1/insights/apps/${appid}?${params}`;
    } else {
      let query = `WHERE appId = '${appid}' AND os = '${device}' AND region = '${region}' AND deviceType = '${deviceType}'`;
      if (this.userService.appInsightsCountryEnabled()) {
        query =
          `WHERE appId = '${appid}' AND os = '${device}' AND region = '${region}' AND country = '${country}' ` +
          `AND deviceType = '${deviceType}'`;
      }
      if (month && month.length > 0) {
        query += ` AND month = '${month}'`;
      }

      urlString = `${this.getReportBaseUrl()}/getWidget?q=${query}&reportId=mobileAppMrt&widgetId=mobileAppSummary&`;
    }

    return this.getCachedObservable(urlString);
  }

  getAvailableFilters(
    appid: string,
    os?: string,
    deviceOption?: string,
    regionOption?: string,
    countryParam?: string
  ) {
    const device = os ? os : this.os;
    const country = countryParam ? countryParam : this.country;
    const region = regionOption ? regionOption : this.region;
    const deviceType = deviceOption ? deviceOption : this.device;

    let query = `WHERE appId = '${appid}' AND os = '${device}' AND region = '${region}' AND deviceType = '${deviceType}'`;
    if (
      this.userService.appInsightsCountryEnabled() &&
      country &&
      country.length > 0
    ) {
      query += ` AND country = '${country}' `;
    }

    const urlString =
      `${this.getReportBaseUrl('2018')}/getWidget?reportId=mobileAppMrt&` +
      `widgetId=availableFilters&q=${query}`;

    return this.getCachedObservable(urlString);
  }

  getCachedObservable(urlString: string) {
    const cacheKey = `${this.auth.getAuthState()}:${urlString}`;
    let cachedObservable = this.appDetailsCachedObservables[cacheKey];

    if (!cachedObservable) {
      cachedObservable = this.http.get(urlString).pipe(shareReplay());
      this.appDetailsCachedObservables[cacheKey] = cachedObservable;
    }

    return cachedObservable.toPromise();
  }

  getWebsiteRatings() {
    return new Promise<WebsiteRating[]>(resolve => {
      const urlString = `${this.getMrtBaseUrl()}/blobs/website-ratings`;
      const cacheKey = `${this.auth.getAuthState()}:${urlString}`;
      const cacheData = this.appWebsiteCacheData[cacheKey];
      if (cacheData) {
        resolve(JSON.parse(JSON.stringify(cacheData)));
      } else {
        this.http.get<WebsiteRating[]>(urlString).subscribe(res => {
          const filteredRes = res.filter(fres => fres.appId);
          this.storeCache(filteredRes, this.appWebsiteCacheData, cacheKey);
          resolve(filteredRes);
        });
      }
    });
  }

  getAppScopeData(
    appid: string,
    os?: string,
    deviceOption?: string,
    regionOption?: string,
    countryParam?: string,
    monthParam?: string
  ) {
    const device = os ? os : this.os;
    const country = countryParam ? countryParam : this.country;
    const region = regionOption ? regionOption : this.region;
    const deviceType = deviceOption ? deviceOption : this.device;
    const month = monthParam ? monthParam : this.month;
    const isFreemium = this.useAnonApi();
    const isFollower = this.isFollower;

    return new Promise(resolve => {
      if (isFreemium) {
        const queryParams = {
          region,
          os: device,
          country,
          month,
          isFreemium,
          isFollower
        };
        const params = jQuery.param(queryParams);
        const urlString = `https://${this.getApigeeHost()}/api/v1/insights/apps/${appid}?${params}`;

        const cacheKey = `${this.auth.getAuthState()}:${urlString}`;

        if (this.availableCountriesCache[cacheKey]) {
          const cacheData = this.availableCountriesCache[cacheKey];
          return resolve(JSON.parse(JSON.stringify(cacheData)));
        }

        this.http.get(urlString).subscribe(res => {
          this.storeCache(res, this.availableCountriesCache, cacheKey);
          resolve(res);
        });
      } else {
        let query = `WHERE appId = '${appid}' AND os = '${device}' AND region = '${region}' AND deviceType = '${deviceType}'`;
        if (this.userService.appInsightsCountryEnabled()) {
          query =
            `WHERE appId = '${appid}' AND os = '${device}' AND region = '${region}' AND country = '${country}' ` +
            `AND deviceType = '${deviceType}'`;
        }
        if (month && month.length > 0) {
          query += ` AND month = '${month}'`;
        }
        const urlString = `${this.getReportBaseUrl()}/getWidget?q=${query}&reportId=mobileAppMrt&widgetId=availableCountries&`;
        const cacheKey = `${this.auth.getAuthState()}:${urlString}`;

        if (this.availableCountriesCache[cacheKey]) {
          const cacheData = this.availableCountriesCache[cacheKey];
          resolve(JSON.parse(JSON.stringify(cacheData)));
        } else {
          this.http.get(urlString).subscribe(res => {
            this.storeCache(res, this.availableCountriesCache, cacheKey);
            resolve(res);
          });
        }
      }
    });
  }

  // https://dev-ratings2-srvc.pixalate.com//services/2012/Ratings/getAppMetadata?&start=0&limit=20&reportId=mobileAppMrt&title=360
  getApps(start: number, limit: number, searchString: string) {
    searchString = encodeURIComponent(searchString);
    let url =
      `${this.getRatingsBaseUrl()}/getAppMetadata?` +
      `start=${start}&limit=${limit}&type=appInfo&title=${searchString}&cache=true`;
    if (this.useAnonApi()) {
      url = `${this.getApigeeBaseUrl(
        'v1'
      )}/ratings/search/app?title=${searchString}`;
    }
    return this.http.get(url);
  }

  getPublishers(start: number, limit: number, searchString: string) {
    return new Promise(resolve => {
      if (searchString && searchString.length > 0) {
        searchString = encodeURIComponent(searchString);
        const urlString = `${this.getRatingsBaseUrl()}/getAppMetadata?&start=0&limit=50&type=publisherName&title=${searchString}`;
        this.http.get(urlString).subscribe(res => {
          if (res && res['docs'] && res['docs'].length > 0) {
            const publishers = res['docs'];
            const retpublishers = publishers.map(publisher => {
              return {
                isPublisher: true,
                publisherName: publisher.publisherName,
                appsCount: 1,
                os: publisher.os
              };
            });
            resolve(retpublishers);
          } else {
            resolve([]);
          }
        });
      } else {
        resolve([]);
      }
    });
  }

  getPublisherEmails(start: number, limit: number, searchString: string) {
    return new Promise(resolve => {
      if (searchString && searchString.length > 0) {
        searchString = encodeURIComponent(searchString);
        const urlString = `${this.getRatingsBaseUrl()}/getAppMetadata?&start=0&limit=50&type=publisherEmail&title=${searchString}`;
        this.http.get(urlString).subscribe(res => {
          if (res && res['docs'] && res['docs'].length > 0) {
            const publishers = res['docs'];
            const retpublishers = publishers.map(publisher => {
              return {
                isPublisherEmail: true,
                publisherEmail: publisher.publisherEmailText,
                appsCount: 1,
                os: publisher.os
              };
            });
            resolve(retpublishers);
          } else {
            resolve([]);
          }
        });
      } else {
        resolve([]);
      }
    });
  }

  getAvailableMonths(reportId = 'mobileAppMrt'): Promise<any> {
    if (!this.availableMonthsCache[reportId]) {
      this.availableMonthsCache[reportId] = new Promise((resolve, reject) => {
        const urlString = `${this.getRatingsBaseUrl()}/getWidget?reportId=${reportId}&widgetId=availableMonths`;
        this.http.get(urlString).subscribe(
          res => {
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
      });
    }
    return this.availableMonthsCache[reportId];
  }

  searchForApps(start: number, limit: number, filterString: string) {
    return new Promise(resolve => {
      let urlString: string;

      if (this.useAnonApi()) {
        urlString = `${this.getFreemiumBaseUrl()}/discovery/apps/summary?start=${start}&limit=${limit}`;
      } else {
        urlString = `${this.getRatingsBaseUrl()}/searchForApps?start=${start}&limit=${limit}`;
      }

      if (this.month) {
        urlString += `&month=${this.month}`;
      }
      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }

      if (
        this.discoveryDataCache[urlString] &&
        urlString.indexOf('userId') < 0
      ) {
        resolve(this.discoveryDataCache[urlString]);
      } else {
        this.http.get(urlString).subscribe(res => {
          this.storeCache(res, this.discoveryDataCache, urlString);
          resolve(res);
        });
      }
    });
  }

  getAppStats(filterStrings: string[] = []) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl('2018')}/appStats?`;
      if (this.month) {
        filterStrings.push(`month=${this.month}`);
      }
      urlString += filterStrings.join('&');
      if (this.discoveryDataCache[urlString]) {
        resolve(this.discoveryDataCache[urlString]);
      } else {
        this.http.get(urlString).subscribe(res => {
          this.storeCache(res, this.discoveryDataCache, urlString);
          resolve(res);
        });
      }
    });
  }

  searchForAppTrends(start: number, limit: number, filterString: string) {
    return new Promise((resolve, reject) => {
      let urlString = `${this.getRatingsBaseUrl()}/${
        this.useAnonApi() ? 'getFreemiumAppTrends' : 'getAppTrends'
      }?start=${start}&limit=${limit}`;

      if (this.month) {
        urlString += `&month=${this.month}`;
      }
      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }
      this.http.get(urlString).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  freemiumSearchForAppTrends(
    start: number,
    limit: number,
    filterString: string
  ) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl()}/getFreemiumAppTrends?start=${start}&limit=${limit}`;
      if (this.month) {
        urlString += `&month=${this.month}`;
      }
      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }
      this.httpGet(urlString, resolve);
    });
  }

  getFacets(filterString: string) {
    return new Promise((resolve, reject) => {
      let urlString =
        `${this.getRatingsBaseUrl()}/getAppFacets?facet=appStore&` +
        `facet=pixalateRating&facet=hasAdsTxt&facet=isExistInAppStore&` +
        `facet=isBlacklisted&facet=isPrivateDomain&facet=appDescriptionBrandSafetyRating&facet=appContentBrandSafetyRating&` +
        `facet=facebookRating&facet=linkedInRating&facet=hasInAppPurchases&` +
        `facet=twitterRating&facet=spoofingRating&facet=isIncentivizedApp&` +
        `facet=locationMaskingRating&facet=viewabilityRating&facet=coppaRisk&facet=coppaTargetChildrenRisk&` +
        `facet=hasVideoCapability&facet=hasAds&facet=adultContentRating&` +
        `facet=drugContentRating&facet=alcoholContentRating&facet=hateSpeechRating&` +
        `facet=offensiveContentRating&facet=gamblingContentRating&facet=topDevice&` +
        `facet=topVideoAdSize&facet=hasVideoCapability&facet=hasAds&facet=overallIvtPermissionRisk&` +
        `facet=violenceContentRating&facet=medicalContentRating&facet=topDisplayAdSize&` +
        `facet=hasUserGeneratedContent&facet=hasPrivacyLink&facet=hasTermsOfServiceLink&facet=mfaRating&` +
        `facet=ownerDomain`;
      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }

      if (this.facetsDataCache[urlString]) {
        resolve(this.facetsDataCache[urlString]);
      } else {
        this.http.get(urlString).subscribe(
          res => {
            this.storeCache(res, this.facetsDataCache, urlString);
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
      }
    });
  }

  getFacetsBySearch(
    selectedDevice: string,
    selectedRegion: string,
    param: string[],
    paramSearch: string,
    paramSearchValue: string,
    filterString?: string
  ) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl()}/getAppFacets`;

      if (selectedRegion) {
        urlString = `${this.getRatingsBaseUrl()}/getAppFacets?region=${selectedRegion}`;
      }

      if (param && param.length > 0) {
        for (const p of param) {
          urlString += `&facet=${p}`;
        }
      }

      if (
        paramSearch &&
        paramSearchValue &&
        paramSearch.length > 0 &&
        paramSearchValue.length > 0
      ) {
        urlString += `&${paramSearch}=${paramSearchValue}`;
      }

      if (selectedDevice) {
        const selectedDeviceString =
          selectedDevice === 'smartphone-tablet'
            ? 'device=smartphone&device=tablet'
            : `device=${selectedDevice}`;
        if (selectedDevice && selectedDevice.length > 0) {
          urlString = urlString.concat(`&${selectedDeviceString}`);
        }
      }

      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }

      if (this.facetsDataCache[urlString]) {
        resolve(this.facetsDataCache[urlString]);
      } else {
        this.http.get(urlString).subscribe(res => {
          this.storeCache(res, this.facetsDataCache, urlString);
          resolve(res);
        });
      }
    });
  }

  getAppTrendFacets(
    selectedDevice: string,
    selectedRegion: string,
    selectedStore: string,
    filterString?: string
  ) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl()}/getAppTrendFacets?region=${selectedRegion}&appStore=${selectedStore}`;

      if (selectedDevice !== 'smartphone-tablet') {
        const selectedDeviceString =
          selectedDevice === 'smartphone-tablet'
            ? ''
            : `device=${selectedDevice}`;
        if (selectedDevice && selectedDevice.length > 0) {
          urlString = urlString.concat(`&${selectedDeviceString}`);
        }
      }

      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }

      this.httpGet(urlString, resolve);
    });
  }

  getSearchCategories(
    name?: string,
    os?: string[],
    start?: number,
    limit?: number
  ) {
    if (!start || start < 0) {
      start = 0;
    }
    if (!limit || limit <= 0) {
      limit = 100;
    }

    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl()}/getAppMetadata?type=category&start=${start}&limit=${limit}`;
      if (name && name.length > 0) {
        const iname = encodeURIComponent(name);
        urlString = urlString.concat(`&name=${iname}`);
      }

      if (os && os.length > 0) {
        for (const o of os) {
          urlString = urlString.concat(`&os=${o}`);
        }
      }
      this.httpGet(urlString, resolve);
    });
  }

  getSearchSellers(start: number, limit: number, name?: string) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl()}/getAppMetadata?type=trustedSeller&start=${start}&limit=${limit}`;
      if (name && name.length > 0) {
        urlString = urlString.concat(`&name=${name}`);
      }
      this.httpGet(urlString, resolve);
    });
  }

  getSearchAgeGroups(name?: string, os?: string[]) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl()}/getAppMetadata?type=ageGroup`;
      if (name && name.length > 0) {
        urlString = urlString.concat(`&name=${name}`);
      }

      if (os && os.length > 0) {
        for (const o of os) {
          urlString = urlString.concat(`&os=${o}`);
        }
      }

      this.httpGet(urlString, resolve);
    });
  }

  getDownloadFilterItems() {
    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl()}/getAppMetadata?type=downloads&start=0&limit=100`;
      this.httpGet(urlString, resolve);
    });
  }

  getSearchPublisherNames(start: number, limit: number, name?: string) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl()}/getAppMetadata?type=publisher&start=${start}&limit=${limit}`;
      if (name && name.length > 0) {
        urlString = urlString.concat(`&name=${name}`);
      }
      this.httpGet(urlString, resolve);
    });
  }

  getSearchPublisherEmails(start: number, limit: number, name?: string) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl()}/getAppMetadata?type=publisherEmail&start=${start}&limit=${limit}`;
      if (name && name.length > 0) {
        urlString = urlString.concat(`&name=${name}`);
      }
      this.httpGet(urlString, resolve);
    });
  }

  getSearchDevices(start: number, limit: number, name?: string) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl()}/getAppMetadata?type=device&start=${start}&limit=${limit}`;
      if (name && name.length > 0) {
        urlString = urlString.concat(`&name=${name}`);
      }
      this.httpGet(urlString, resolve);
    });
  }

  getSearchAdSizes(start: number, limit: number, name?: string) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl()}/getAppMetadata?type=displayAdSize&start=${start}&limit=${limit}`;
      if (name && name.length > 0) {
        urlString = urlString.concat(`&name=${name}`);
      }
      this.httpGet(urlString, resolve);
    });
  }

  getMaxInventory() {
    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl()}/getAppMetadata?type=maxInventory`;
      this.httpGet(urlString, resolve);
    });
  }

  isNotEmpty(s: string) {
    return s && s.length > 0;
  }

  getSearchVideoAdSizes(start: number, limit: number, name?: string) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl()}/getAppMetadata?type=videoAdSize&start=${start}&limit=${limit}`;
      if (this.isNotEmpty(name)) {
        urlString = urlString.concat(`&name=${name}`);
      }
      this.httpGet(urlString, resolve);
    });
  }

  downloadCsv(reportId: string, filterString: string) {
    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl()}/getExportUri?reportId=${reportId}&${filterString}&isAsync=true`;
      this.httpGet(urlString, resolve);
    });
  }

  checkImportQuota() {
    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl()}/accountBalance`;
      this.http.get(urlString).subscribe(res => {
        resolve(res);
      });
    });
  }

  importCSV(formData: FormData) {
    const headers = new Headers();
    headers.append('Content-type', 'application/octet-stream');

    return new Promise(resolve => {
      return this.http
        .post(`${this.getRatingsBaseUrl()}/importApps`, formData)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            resolve(err);
          }
        );
    });
  }

  getImportList() {
    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl('2018')}/listAppImport`;
      this.http.get(urlString).subscribe(res => {
        this.storeCache(res, this.discoveryDataCache, urlString);
        resolve(res);
      });
    });
  }

  deleteImportedFile(bulkUploadReportId: string) {
    if (bulkUploadReportId) {
    }
  }

  getReportData() {
    return new Promise(resolve => {
      if (!this.reportDataCache) {
        const urlString = `${this.getRatingsBaseUrl(
          '2018'
        )}/loadReport?reportId=appSummary`;
        return this.http.get(urlString).subscribe(response => {
          this.reportDataCache = response;
          resolve(response);
        });
      } else {
        resolve(this.reportDataCache);
      }
    });
  }

  // https://dev-ratings2-srvc.pixalate.com//services/2012/Report/getImportStatus?bulkUploadReportId=15375175328351
  // https://dev-ratings2-srvc.pixalate.com//services/2012/Ratings/getImportStatus?bulkUploadReportId=1537517532835
  getImportStatus(bulkUploadReportId: string) {
    return new Promise((resolve, reject) => {
      const urlString = `${this.getRatingsBaseUrl()}/getImportStatus?bulkUploadReportId=${bulkUploadReportId}`;
      return this.http.get(urlString).subscribe(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  savePreset(formData: any) {
    return new Promise((resolve, reject) => {
      return this.http
        .post(`${this.getRatingsBaseUrl('2018')}/saveReport`, formData)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  listPresets(groupName: string) {
    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl(
        '2018'
      )}/listReport?group=${groupName}`;
      this.httpGet(urlString, resolve);
    });
  }

  loadPreset(reportId: string) {
    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl(
        '2018'
      )}/loadReport?reportId=${reportId}`;
      this.httpGet(urlString, resolve);
    });
  }

  deletePreset(reportId: string) {
    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl(
        '2018'
      )}/deleteReport?reportId=${reportId}`;
      this.httpGet(urlString, resolve);
    });
  }

  /**
   * Helper function to get and resolve
   *
   * @param urlString
   * @param resolve
   */
  private httpGet(urlString: string, resolve: any): void {
    this.http.get(urlString).subscribe(response => {
      resolve(response);
    });
  }
}
