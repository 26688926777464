import { NgModule } from '@angular/core';
import { AppDetailsLegacyComponent } from './app-details-legacy/app-details-legacy.component';
import { BrowserModule } from '@angular/platform-browser';
import { DomainDetailsLegacyComponent } from './domain-details-legacy/domain-details-legacy.component';
import { FnaPopupComponent } from './fna-popup/fna-popup.component';
import { Footer } from './footer/footer';
import { LandingPageRoutingComponent } from './landing-page-routing/landing-page-routing.component';
import { RankingsPageRoutingComponent } from './rankings-page-routing/rankings-page-routing.component';
import { DiscoveryPageRoutingComponent } from './discovery-page-routing/discovery-page-routing.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TooltipModule } from '../tooltip/tooltip.module';
import { DataTableModule } from '../data-table/data-table.module';
import { appRoutingProviders, routing } from './new-navigation.routing.';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { NewNavigationComponent } from './new-navigation.component';
import { KcsOverlayModule } from './keyword-contextual-search/kcs-overlay/kcs-overlay.module';
import { PipesModule } from 'app/pipes/pipes.module';

@NgModule({
  declarations: [
    FnaPopupComponent,
    Footer,
    DomainDetailsLegacyComponent,
    AppDetailsLegacyComponent,
    LandingPageRoutingComponent,
    RankingsPageRoutingComponent,
    DiscoveryPageRoutingComponent,
    PageNotFoundComponent,
    NewNavigationComponent
  ],
  imports: [
    SharedModule,
    TooltipModule,
    DataTableModule,
    BrowserModule,
    FormsModule,
    KcsOverlayModule,
    PipesModule,
    routing
  ],
  providers: [appRoutingProviders]
})
export class NewNavigationModule {}
