import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api-service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class QuotaService extends BaseApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getQuotaUsage() {
    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl()}/accountBalance`;
      this.http.get(urlString).subscribe(res => {
        resolve(res);
      });
    });
  }

  getActivityLogs(start = 0, limit = 15) {
    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl(
        '2020'
      )}/getActivityLogs?start=${start}&limit=${limit}`;
      this.http.get(urlString).subscribe(res => {
        resolve(res);
      });
    });
  }
}
