import {Pipe, PipeTransform, Injectable} from '@angular/core';

@Pipe({name: 'urlfix'})
@Injectable()
export class UrlfixPipe implements PipeTransform {
     transform(value: any, arg0?: string): any {
        if (value && (value.indexOf('http:') >= 0 || value.indexOf('https:') >= 0)) {
          return value;
        } else {
          return `https://${value}`;
        }
    }
}
