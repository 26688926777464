import { GridHeader } from './GridHeader';
import { User } from '../../models/User';
import { UrlfixPipe } from '../../pipes/urlfix.pipe';
import { NumberFormatStyle, NumberFormatter } from '../../pipes/perc.pipe';
var validator = require('validator');
import numeral from 'numeral';
declare var jQuery;

export let IGNORE_SCROLLING_LISTENER: boolean = false;
const _re = new RegExp('^(\\d+)?\\.((\\d+)(\\-(\\d+))?)?$');
const defaultLocale: string = 'en-US';

export const UtilFunctions = {
  monthNames: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ],

  urlfixPipe: new UrlfixPipe(),

  getChangeHTML: function (cl: string, value: string) {
    if (cl === 'increase') {
      return `<span class="increase">${value}&nbsp;<i class="fa fa-arrow-up"></i></span>`;
    } else if (cl === 'decrease') {
      return `<span class="decrease">${value}&nbsp;<i class="fa fa-arrow-down"></i></span>`;
    } else if (cl === 'increase-ivt') {
      return `<span class="increase">${value}&nbsp;<i class="fa fa-arrow-down"></i></span>`;
    } else if (cl === 'decrease-ivt') {
      return `<span class="decrease">${value}&nbsp;<i class="fa fa-arrow-up"></i></span>`;
    } else {
      return '';
    }
  },

  toWebsiteURL: function (value, validator) {
    let website = 'N/A';
    if (value) {
      const websiteString = this.urlfixPipe.transform(value);
      try {
        const isValidUrl = validator.isURL(websiteString);
        const websiteUrl: URL = new URL(websiteString);
        const isFQDN = validator.isFQDN(websiteUrl.hostname);
        if (!isValidUrl || !isFQDN) {
          website = 'N/A';
        } else {
          website = websiteString;
        }
      } catch (e) {
        console.log('CANNOT PARSE: ' + e.message);
        website = 'N/A';
      }
    }
    return website;
  },

  getDataHTML: function (
    dataItem: any,
    header: GridHeader,
    gridHeaders: Array<GridHeader>,
    user: User,
    indexId?: string
  ) {
    if (header.template) {
      let template = header.template;
      let keys = template.match(/\${([^}]+)}/gi);
      let html = template;
      keys.forEach(findKey => {
        let key = findKey.substring(2, findKey.length - 1);
        let value = dataItem[key] ? dataItem[key] : '';

        if (key === 'isFreemium') {
          html = html.replace(findKey, (!!user.isFreemium()).toString());
          return;
        }

        if (key.endsWith('Length')) {
          const temp = dataItem[key.replace('Length', '')];
          value = (Array.isArray(temp) ? temp.length : 0).toString();
        } else if (key.endsWith('List')) {
          const temp = dataItem[key.replace('List', '')];
          if (temp && Array.isArray(temp)) {
            let tempstring = '<ul class="list-unstyled p-0 m-0">';
            temp.forEach(t => {
              tempstring += `<li>${t}</li>`;
            });
            tempstring += '</ul>';
            value = tempstring;
          } else {
            value = temp;
          }
        } else if (key.indexOf('Raw')) {
          let rawKey = key.replace('Raw', '');
          value = dataItem[rawKey];
        }
        if (key === 'indexId') {
          value = indexId;
          html = html.replace(findKey, value);
        } else if (key === 'ctvInsightsLink') {
          const ctvInsightsLink = `/insight/ctv/${dataItem.appId}/${dataItem.region}/${dataItem.device}`;
          html = html.replace(findKey, ctvInsightsLink);
        } else if (key === 'domainInsightsLink') {
          const domainInsightsLink = `/insight/domains/${dataItem.domain}/${dataItem.region}/${dataItem.device}`;
          html = html.replace(findKey, domainInsightsLink);
        } else if (key === 'appInsightsLink') {
          const appInsightsLink = `/insight/apps/${dataItem.appId}/${dataItem.region}/${dataItem.appStore}/${dataItem.device}`;
          html = html.replace(findKey, appInsightsLink);
        } else {
          let headerFormat = gridHeaders.filter(header => header.key === key)[0]
            .format;
          if (
            value !== null &&
            value !== '' &&
            value !== 'N/A' &&
            headerFormat
          ) {
            value = this.formatValue(value, headerFormat);
          }

          if (key.indexOf('Change') >= 0) {
            let changeHtml = '';
            if (
              dataItem[key] === 0 ||
              value === '0%' ||
              value === '0' ||
              value === 0
            ) {
              changeHtml =
                '<span>' + value + ' <i class="fa fa-minus"></i></span>';
            } else if (dataItem[key] < 0) {
              changeHtml = this.getChangeHTML('decrease', value);
              if (key === 'ivtRateChange' || key === 'givtSivtRateChange') {
                changeHtml = this.getChangeHTML('increase-ivt', value);
              }
            } else if (dataItem[key] > 0) {
              changeHtml = this.getChangeHTML('increase', value);
              if (key === 'ivtRateChange' || key === 'givtSivtRateChange') {
                changeHtml = this.getChangeHTML('decrease-ivt', value);
              }
            } else {
              changeHtml = '<span>' + value + '</span>';
            }
            html = html.replace(findKey, changeHtml);
          } else {
            if (value) {
              if (key === 'region' && value === 'NA') {
                value = 'North America';
              }

              if (
                typeof value === 'string' &&
                value.indexOf('http') >= 0 &&
                value.indexOf('-rw') >= 0
              ) {
                //google play app icons url
                value = value.replace('-rw', '');
              }

              if (value === 'NON-US') {
                value = 'Non U.S.';
              }

              if (!value || value === 'undefined') {
                value = '';
              }

              if (key === 'developerWebsite') {
                value = this.toWebsiteURL(value, validator);
              }

              // if (value === '00.00%' && dataItem[key] !== 0) {
              //   console.log('ACTUAL VALUE: ' + dataItem[key]);
              //   value = '< 1%';
              // }

              html = html.replace(findKey, value);
            } else {
              if (dataItem && header && header.key && dataItem[header.key]) {
                value = dataItem[header.key][key];
                if (!value || value === 'undefined') {
                  value = '';
                }
                if (key === 'region' && value === 'NA') {
                  html = html.replace(findKey, 'North America');
                } else if (value !== 'N/A') {
                  html = html.replace(findKey, value);
                } else {
                  html = html.replace(findKey, value);
                }
              }
            }
          }
        }
      });

      return html;
    } else {
      return dataItem[header.key] ? dataItem[header.key] : '';
    }
  },

  formatValue: function (input: any, format: string) {
    if (
      typeof input === 'string' &&
      (format === 'big-number' ||
        format === 'big-number-no-decimal' ||
        format === 'pretty-number' ||
        format === 'percent3digits' ||
        format === 'order')
    ) {
      input = Number.parseFloat(input);
    }

    if (input) {
      let value: any;
      if (format === 'big-number') {
        value = numeral(input).format('0.00a');
      } else if (format === 'big-number-no-decimal') {
        value = numeral(input).format('0a');
        value = value.toUpperCase();
      } else if (format === 'pretty-number') {
        value = numeral(input).format('0,0');
      } else if (input !== 0 && format === 'percent') {
        // value = numeral(Math.abs(input)).format('00.00%');
        value = this.percentTransform(input, '1.2-2');
        //if (value === '0.00%') { value = '0%'; }
      } else if (input !== 0 && format === 'percent3digits') {
        value = numeral(Math.abs(input)).format('0.000%');
      } else if (format === 'order') {
        value = numeral(Math.abs(input)).format('0,0o');
      } else if (format === 'urlfix') {
        value = this.urlfixPipe.transform(input);
      } else if (format === 'boolean') {
        switch (input) {
          case 'T':
            value = 'Yes';
            break;
          case 'F':
            value = 'No';
            break;
          case 'true':
            value = 'Yes';
            break;
          case 'false':
            value = 'No';
            break;
          default:
            value = 'N/A';
            break;
        }
      } else if (format === 'decimal') {
        value = numeral(input).format('0.00');
      } else if (format === 'number') {
        value = Math.abs(input);
      } else if (format === 'array') {
        value = input.join(', ');
      } else if (format === 'length') {
        value = input && Array.isArray(input) ? input.length : 0;
      } else {
        value = input;
      }
      return value;
    } else {
      if (
        format === 'number' ||
        format === 'big-number' ||
        format === 'pretty-number'
      ) {
        return '0';
      } else if (format === 'percent' || format === 'percent3digits') {
        return '0%';
      } else {
        return '';
      }
    }
  },

  focusLogo: function (appIconElement: any) {
    jQuery('body').append('<div class="details-overlay-transition"></div>');
    let width = jQuery(appIconElement).width();
    let height = jQuery(appIconElement).height();

    let rect = appIconElement.getBoundingClientRect();
    let transOverlay = jQuery('.details-overlay-transition')[0];
    let imgSrc = jQuery(appIconElement).attr('src');
    jQuery(transOverlay).append(
      `<img class="overlay-image" src="${imgSrc}" width="${width}" height="${height}"/>`
    );

    jQuery('img.overlay-image').css('position', 'fixed');
    jQuery('img.overlay-image').css('top', rect.top + 'px');
    jQuery('img.overlay-image').css('left', rect.left + 'px');
    jQuery('img.overlay-image').css('border', '1px solid #000000');

    jQuery('body').append(
      '<div class="details-overlay-transition-message">Accessing data&nbsp;<i class="fa fa-spin fa-spinner"></i></div>'
    );
    jQuery('div.details-overlay-transition-message').css('position', 'fixed');
    jQuery('div.details-overlay-transition-message').css(
      'top',
      rect.top + 'px'
    );
    jQuery('div.details-overlay-transition-message').css('left', '50%');

    return rect;
  },

  getDateString: function (lastUpdateString: string) {
    if (lastUpdateString) {
      let datearr = lastUpdateString.split('-');
      let year = Number.parseInt(datearr[0]);
      let month = Number.parseInt(datearr[1]) - 1;
      let day = Number.parseInt(datearr[2]);
      return `${this.monthNames[month]} ${day}, ${year}`;
    } else {
      return lastUpdateString;
    }
  },

  isNumber: function (input: any) {
    return typeof input === 'number' || !isNaN(input);
  },

  isString: function (input: any) {
    return typeof input === 'string';
  },

  percentTransform: function (value, args: string): any {
    if (value !== 'N/A') {
      const digits: string = args;
      if (value > 0) {
        let retString = this.percentFormat(
          value,
          NumberFormatStyle.Percent,
          digits
        );
        retString = retString === '100.00%' ? '100%' : retString;
        retString = retString === '00.00%' ? '< 1%' : retString;
        return retString;
      } else {
        return '0%';
      }
    } else {
      return 'N/A';
    }
  },

  percentFormat: function (
    value: number,
    style: NumberFormatStyle,
    digits: string,
    currency: string = null,
    currencyAsSymbol: boolean = false
  ): string {
    if (!value) {
      return null;
    }
    if (!UtilFunctions.isNumber(value)) {
      return 'N/A';
      // throw new InvalidPipeArgumentException(PercPipe, value);
    }
    let minInt = 1,
      minFraction = 0,
      maxFraction = 3;
    if (digits) {
      const parts = digits.match(_re);
      if (!parts) {
        throw new Error(`${digits} is not a valid digit info for number pipes`);
      }
      var p1 = parseInt(parts[1]);
      if (!isNaN(p1)) {
        // min integer digits
        minInt = p1;
      }
      var p3 = parseInt(parts[3]);
      if (!isNaN(p3)) {
        // min fraction digits
        minFraction = p3;
      }
      var p5 = parseInt(parts[5]);
      if (!isNaN(p5)) {
        // max fraction digits
        maxFraction = p5;
      }
    }
    return NumberFormatter.format(value, defaultLocale, style, {
      minimumIntegerDigits: minInt,
      minimumFractionDigits: minFraction,
      maximumFractionDigits: maxFraction,
      currency: currency,
      currencyAsSymbol: currencyAsSymbol
    });
  }
};

/**
 * @param {?} obj
 * @return {?}
 */
export function isPresent(obj) {
  return obj != null;
}
/**
 * @param {?} obj
 * @return {?}
 */
export function isBlank(obj) {
  return obj == null;
}

export var NumberWrapper = (function () {
  function NumberWrapper() {}
  /**
   * @param {?} text
   * @return {?}
   */
  NumberWrapper.parseIntAutoRadix = function (text) {
    var /** @type {?} */ result = parseInt(text);
    if (isNaN(result)) {
      throw new Error('Invalid integer literal when parsing ' + text);
    }
    return result;
  };
  /**
   * @param {?} value
   * @return {?}
   */
  NumberWrapper.isNumeric = function (value) {
    return !isNaN(value - parseFloat(value));
  };
  return NumberWrapper;
})();

/**
 * @param {?} token
 * @return {?}
 */
export function stringify(token) {
  if (typeof token === 'string') {
    return token;
  }
  if (token == null) {
    return '' + token;
  }
  if (token.overriddenName) {
    return '' + token.overriddenName;
  }
  if (token.name) {
    return '' + token.name;
  }
  var /** @type {?} */ res = token.toString();
  var /** @type {?} */ newLineIndex = res.indexOf('\n');
  return newLineIndex === -1 ? res : res.substring(0, newLineIndex);
}
