<div id="mask" *ngIf="showPopup || showNoMonthFound || showNoDomainFound"></div>
<div class="aa-app-data-notice" *ngIf="showPopup">
  <div class="aa-paywall-section">
    <a class="close pull-xl-right" (click)="showPopup = false">
      <i class="fa fa-close"></i>
    </a>
    <p class="header">This feature is part of our enterprise premium offering.</p>
    <p>To get started, please contact your sales representative for more details.</p>
    <div class="button-bar">
      <button class="btn btn-lg btn-primary" (click)="contact()" (keydown)="showPopup = false;" autofocus>Contact
        Sales</button>
    </div>
  </div>
</div>

<div class="aa-app-data-notice" *ngIf="showDownloadCsvLimitPopup">
  <div class="aa-paywall-section">
    <a class="close pull-xl-right" (click)="showDownloadCsvLimitPopup = false">
      <i class="fa fa-close"></i>
    </a>
    <p class="header">Current results exceeds 1M.</p>
    <p>Apply filters to reduce the result size before downloading a CSV.</p>
    <div class="button-bar">
      <button class="btn btn-primary" (click)="showDownloadCsvLimitPopup = false"
        (keydown)="showDownloadCsvLimitPopup = false;" autofocus>Close</button>
    </div>
  </div>
</div>

<div class="aa-app-data-notice" *ngIf="showDownloadDeductionPopup">
  <div class="aa-paywall-section">
    <a class="close pull-xl-right" (click)="showDownloadDeductionPopup = false">
      <i class="fa fa-close"></i>
    </a>
    <p class="header">Quota Deduction</p>
    <p>{{downloadDecutionValue | num}} amount will be deducted to your quota. <br>Do you want to continue?</p>
    <br>
    <div class="button-bar">
      <button class="btn btn-secondary mr-2" (click)="showDownloadDeductionPopup = false"
        (keydown)="showDownloadDeductionPopup = false;">Cancel</button>
      <button class="btn btn-primary" (click)="downloadCsvClick()" (keydown)="showImportCsvPopup = false;"
        autofocus>Proceed</button>

    </div>
  </div>
</div>

<div class="aa-app-data-notice" *ngIf="showQuotaError">
  <div class="aa-paywall-section">
    <a class="close pull-xl-right" (click)="showQuotaError = false;">
      <i class="fa fa-close"></i>
    </a>
    <p class="header m-b-1">Download failed due to insufficient quota.</p>

    <div class="button-bar">
      <button class="btn btn-primary" (click)="showQuotaError = false;" (keydown)="showQuotaError = false;" autofocus>
        OK
      </button>
    </div>
  </div>
</div>

<div class="aa-app-data-notice" *ngIf="showImportCsvPopup">
  <div class="aa-paywall-section">
    <a class="close pull-xl-right" (click)="showImportCsvPopup = false">
      <i class="fa fa-close"></i>
    </a>
    <p class="header"></p>
    <p class="form-check mb-5">
      <label class="form-check-label">
        <input type="checkbox" class="form-check-input mr-2" [(ngModel)]="removeDuplicates" />
        Remove Duplicates
      </label>
    </p>
    <div class="button-bar d-flex justify-content-between">
      <button class="btn btn-secondary" (click)="showImportCsvPopup = false;" (keydown)="showImportCsvPopup = false;"
        autofocus>Cancel</button>
      <button class="btn btn-primary" (click)="importCsvClick()" (keydown)="showImportCsvPopup = false;"
        autofocus>Import CSV</button>
    </div>
  </div>
</div>


<div class="aa-app-data-notice" *ngIf="showNoMonthFound">
  <div class="aa-paywall-section">
    <a class="close pull-xl-right" (click)="showNoMonthFound = false;">
      <i class="fa fa-close"></i>
    </a>
    <p class="header m-b-1">Unavailable for the month of {{loadingMonth}}</p>

    <div class="button-bar">
      <button class="btn btn-primary" (click)="showNoMonthFound = false;" (keydown)="showNoMonthFound = false;"
        autofocus>
        OK
      </button>
    </div>
  </div>
</div>
<div class="aa-app-data-notice" *ngIf="showServerError">
  <div class="aa-paywall-section">
    <a class="close pull-xl-right" (click)="showServerError = false;">
      <i class="fa fa-close"></i>
    </a>
    <p class="header m-b-1">Backend service unavailable...</p>

    <div class="button-bar">
      <button class="btn btn-primary" (click)="showServerError = false;" (keydown)="showServerError = false;" autofocus>
        OK
      </button>
    </div>
  </div>
</div>

<div class="aa-app-data-notice" *ngIf="showNoDomainFound">
  <div class="aa-paywall-section">
    <a class="close pull-xl-right" (click)="showNoDomainFound = false;">
      <i class="fa fa-close"></i>
    </a>
    <p class="header m-b-1 text-xs-center">{{loadingDomain}} is unavailable.</p>

    <div class="button-bar">
      <button class="btn btn-primary" (click)="showNoDomainFound = false;" (keydown)="showNoDomainFound = false;"
        autofocus>
        OK
      </button>
    </div>
  </div>
</div>

<!-- What's new Modal -->
<div class="modal fade" id="whatsNewModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title text-uppercase" id="exampleModalLabel">What's new in this release</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          This update aims to improve efficiency and help you get information with fewer clicks, less key strokes, and
          optimized loading
          across the entire MRT. Changes to the Import CSV to allow you to filter out data you don't need. Changes to
          the
          search box for more efficient searches. More details below.
        </div>
        <div class="my-3">
          We hope you enjoy this update, and if you experience any issues or have any feedback, please let us know using
          the "Have
          Feedback" form in the bottom right corner of the MRT.
        </div>
        <div id="accordion" class="whats-new-accordion" role="tablist" aria-multiselectable="true">
          <div class="card">
            <div class="card-header text-left" role="tab" id="headingOne">
              <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne"
                aria-expanded="false" aria-controls="collapseOne">
                <img class="card-header-icon ionic-plus" src="/assets/images/ionic-plus.svg" alt="Plus Icon" />
                <img class="card-header-icon ionic-minus" src="/assets/images/ionic-minus.svg" alt="Minus Icon" />
                Navigation Revamp
              </a>
            </div>

            <div id="collapseOne" class="collapse text-left" role="tabpanel" aria-labelledby="headingOne">
              <div class="card-block text-left">
                <ul>
                  <li>Access each section with one click</li>
                  <li>Easily switch between Domains and Apps with one click in Insights or Discovery</li>
                  <li>Smaller sub navigation icons allows for less cursor movement</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header text-left" role="tab" id="headingTwo">
              <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo"
                aria-expanded="false" aria-controls="collapseTwo">
                <img class="card-header-icon ionic-plus" src="/assets/images/ionic-plus.svg" alt="Plus Icon" />
                <img class="card-header-icon ionic-minus" src="/assets/images/ionic-minus.svg" alt="Minus Icon" />
                Import CSV Changes
              </a>
            </div>
            <div id="collapseTwo" class="collapse text-left" role="tabpanel" aria-labelledby="headingTwo">
              <div class="card-block text-left">
                <ul>
                  <li>Import results can now be viewed in the UI before downloading CSV</li>
                  <li>Filters can now be applied to import results</li>
                  <li>You can now select which columns to include in your downloaded CSV</li>
                  <li>
                    <span class="text-highlight">A link to download the CSV will no longer be emailed to you</span>
                    <ul>
                      <li>You can wait on the Import page for the import results</li>
                      <li>You can save the url and check back at a later time</li>
                      <li>Imports usually take between 1 - 10 minutes depending on list size</li>
                      <li>In our next release we will send an email with the import details and a link to view the
                        results page
                        for convenience</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header text-left" role="tab" id="headingThree">
              <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree"
                aria-expanded="false" aria-controls="collapseThree">
                <img class="card-header-icon ionic-plus" src="/assets/images/ionic-plus.svg" alt="Plus Icon" />
                <img class="card-header-icon ionic-minus" src="/assets/images/ionic-minus.svg" alt="Minus Icon" />
                Search box Improvements
              </a>
            </div>
            <div id="collapseThree" class="collapse text-left" role="tabpanel" aria-labelledby="headingThree">
              <div class="card-block text-left">
                <ul>
                  <li>The matching input characters in the search results are now bold</li>
                  <li>You can use the arrow keys to move the highlighted search result up and down</li>
                  <li>You can now press enter to select the highlighted item</li>
                  <li>If a result matches your input it will be moved to the top of the list</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="aa-app-data-notice" *ngIf="showInvitePopup">
  <div class="aa-paywall-section">
    <a class="close pull-xl-right" (click)="showInvitePopup = false">
      <i class="fa fa-close"></i>
    </a>
    <div class='text-left'>
      <p class="header text-left">
        <b>Invite your publishers to MRT Basic Edition today!</b>
      </p>
      <p class='text-left'>
        Giving publishers the ability to manage their quality and trust in one platform.
      </p>
      <p class='text-left'>
        <b>Your publisher will gain powerful insights and understand how buyers evaluate their business.</b>
      </p>
      <ul class='text-left pl-3'>
        <li>View your publisher score.</li>
        <li>Request “Publisher Diagnostic Report”.</li>
        <li>Receive recommendations for free.</li>
      </ul>
    </div>
    <p class="form-group mb-3 text-left">
      <input type="email" class="form-control mb-2" id="inviteEmail" placeholder="Enter email"
        [(ngModel)]="inviteEmail">
      <textarea class="form-control" id="inviteMessage" placeholder="Enter a custom message" [(ngModel)]="inviteMessage"
        maxlength="1000"></textarea>
    </p>

    <div class="button-bar d-flex justify-content-between">
      <button class="btn btn-primary btn-block" [disabled]="!inviteEmail || inviting" (click)="inviteClick()"
        (keydown)="showInvitePopup = false;" autofocus>Invite</button>
    </div>
  </div>
</div>